.container {
  margin: 0 auto;
  margin-bottom: 50px;
  padding: 0 var(--unit_4);
  max-width: 600px;
}

:global(#newbuilding-recommendations) { /* stylelint-disable-line */
  margin: 0 calc(-1 * var(--unit_4));
}

@media (min-width: 632px) {
  .container {
    padding: 0;
  }

  :global(#newbuilding-recommendations) { /* stylelint-disable-line */
    margin: 0;
  }
}
